<template>
  <section class="video-wrapper">
    <img src="@/assets/svg/icons/title-icon-mob.svg"
         class="section-title-decoration mobile sm-none" />

    <img src="@/assets/svg/icons/title-icon.svg"
         class="section-title-decoration desktop" />

    <h1 class="section-title">видео о нашей работе</h1>

    <div class="bg-img">
      <b-button class="play"
                @click="showModal">
        <img src="@/assets/svg/icons/play.svg" />
      </b-button>
    </div>

    <Modal v-model="isShow"
           :close="closeModal"
    >
      <div class="modal" ref="modalRef">
        <YouTube
          src="https://www.youtube.com/watch?v=s_8ONwsT2so"
          @ready="onReady"
          ref="youtube"
          :width="width"
          :height="height"
          v-if="width"
        />

        <img src="@/assets/svg/icons/close.svg"
             @click="closeModal"
             class="close" />
      </div>
    </Modal>
  </section>
</template>

<script>
import YouTube from 'vue3-youtube'
import { defineComponent, ref, nextTick } from 'vue'

export default {
  name: 'Video',

  components: {
    YouTube,
  },

  setup() {
    const isShow = ref(false);
    const modalRef = ref(null);
    const height = ref(null);
    const width = ref(null);

    function showModal() {
      isShow.value = true;

      nextTick(() => {
        width.value = modalRef.value.clientWidth
        height.value = modalRef.value.clientHeight - 100
      })
    }

    function closeModal() {
      isShow.value = false;

      width.value = null
      height.value = null
    }

    return {
      modalRef,
      isShow,
      height,
      width,
      showModal,
      closeModal,
    };
  },

  data() {
    return {
    }
  },
  methods: {
    onReady() {
        this.$refs.youtube.playVideo()
    },
  },
}
</script>

<style scoped lang="scss">
.video-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  padding-bottom: 180px;
  background-repeat: no-repeat;
  background-position: top 150px right;
  background-size: 95%;
  position: relative;
  background-image: url('@/assets/svg/video-bg.svg');

  @media(max-width: 1400px){
    margin-top: -100px;
  }

  @media(max-width: 768px){
    background-size: 200%;
    background-position: bottom -140px left 90px;
    background-image: url('@/assets/svg/certification-bg.svg');
  }

  @media(max-width: 576px){
    background-size: 150%;
    padding-top: 60px;
    margin-top: -110px;
    padding-bottom: 60px;
    background-position: bottom center;
    background-image: url('@/assets/svg/certification-bg.svg');
  }
}

.bg-img {
  background-image: url('@/assets/video-img.png');
  width: 100%;
  height: 100%;
  min-height: 730px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;

  @media(max-width: 576px){
    min-height: 323px;
  }
}

.section-title {
  margin-bottom: 38px;
  margin-top: -13px;
  margin-left: 95px;

  @media(max-width: 1000px){
    margin-left: 40px;
  }

  @media(max-width: 576px){
    margin-left: 20px;
  }
}

.play {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%);
  width: 114px;
  height: 114px;
  background-color: $blue;
  border-radius: 50%;
  transition: .6s all;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(max-width: 576px){
    height: 49px;
    width: 49px;

    img {
      max-width: 16px;
    }
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $dark-blue;
  }
}

.section-title-decoration {
  margin-top: -30px;
}

.modal {
  max-width: 80%;
  height: 600px;
  max-height: 600px;
  padding: 30px;
  max-height: 80%;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 20px;
  position: relative;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;

  .close {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 20px;
    margin-right: 20px;
    max-width: 32px;
    max-height: 32px;
    cursor: pointer;
    transition: .6s all;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
