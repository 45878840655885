<template>
  <section class="products-range-wrapper">
    <img src="@/assets/svg/products-range-polygon.svg"
         class="polygon polygon__right" />

    <img src="@/assets/svg/products-range-polygon.svg"
         class="polygon polygon__left" />

    <div class="content-container">
      <h1 class="section-title">карта ассортимента</h1>

      <b-row class="w-100 flex-wrap">
        <b-col class="d-flex col"
               xl=""
               lg=""
               md=""
               sm="12"
        >
          <div class="col-title">
            <p>Стретч-пленки</p>
          </div>

          <div class="col-text">
            <p>Ручная</p>

            <p>Машинная</p>

            <p>Мини</p>

            <p>Первичная</p>

            <p>Вторичная</p>

            <p>Цветная</p>

            <p>MIX</p>
          </div>
        </b-col>

        <b-col class="d-flex col"
               xl=""
               lg=""
               md=""
               sm="12"
        >
          <div class="col-title">
            <p>гофроупаковка</p>
          </div>

          <div class="col-text nowrap">
            <p>гофрокоробка</p>

            <p>гофроящики</p>

            <p>листы</p>

            <p>подложки</p>

            <p>рулонный картон</p>

            <p>двухслойный</p>

            <p>трехслойный</p>

            <p>пятислойный</p>
          </div>
        </b-col>

        <b-col class="d-flex col"
               xl=""
               lg=""
               md=""
               sm="12"
        >
          <div class="col-title">
            <p>клейкие ленты</p>
          </div>

          <div class="col-text nowrap">
            <p>скотч стандартный</p>

            <p>скотч машинный</p>

            <p>скотч с логотипом</p>

            <p>скотч цветной</p>

            <p>скотч двухсторонний</p>
          </div>
        </b-col>

        <b-col class="d-flex col"
               xl=""
               lg=""
               md="4"
               sm="12"
        >
          <div class="col-title">
            <p>расходные материалы</p>
          </div>

          <div class="col-text nowrap">
            <p>перчатки</p>

            <p>пвд</p>

            <p>мешки</p>

            <p>пакеты</p>

            <p>ленты пп</p>

            <p>скобы для лент пп</p>

            <p>кассовые ленты</p>

            <p>красящая лента</p>

            <p>бумага А4</p>
          </div>
        </b-col>

        <b-col class="d-flex col"
               xl=""
               lg=""
               md="3"
               sm="12"
        >
          <div class="col-title">
            <p>самоклеящиеся этикетки</p>
          </div>

          <div class="col-text">
            <p>термоэтикетки (эко и топ)</p>

            <p>термотрансферные этикетки</p>

            <p>велум</p>

            <p>плг</p>

            <p>полипропилен</p>

            <p>полиэтилен</p>

            <p>риббон</p>

            <p>нанесение логотипа</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ProductsRange',

  setup () {
  },

  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.products-range-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 130px 0px;
  justify-content: center;
  position: relative;
  background-color: $light-blue;

  @media(max-width: 576px){
    padding: 60px 0;
  }
}

.content-container {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

h1 {
  color: $white;
}

.polygon {
  position: absolute;

  &__left {
    transform: scaleX(-1);
    left: 0;
    bottom: 0;

    @media(max-width: 576px){
      margin-left: -50px;
    }
  }

  &__right {
    right: 0;
    top: 0;
    margin-top: 15px;

    @media(max-width: 576px){
      margin-right: -50px;
      margin-top: 30px;
    }
  }
}

.col-title {
  writing-mode: vertical-rl;
  white-space: nowrap;
  color: $white;
  font-weight: 600;
  font-size: 22px;
  text-transform: uppercase;
  margin-right: 10px;

  p {
    transform: rotate(180deg);
    text-align: right;
    margin: 0;
  }
}

.col-text {
  color: $white;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  margin-top: -5px;

  @media(max-width: 768px){
    font-weight: 400;
  }

  p {
    margin: 0;
    margin-bottom: 25px;
  }
}

.section-title {
  margin-bottom: 90px;
}

.col {
  width: fit-content;

  @media(max-width: 1300px){
    padding: 0 20px;
    padding-bottom: 70px;
  }

  @media(max-width: 576px){
    width: 100%;
    flex: 1 1 1;
    padding-left: 5px;
  }
}
</style>
