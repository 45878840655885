<template>
  <section class="wrapper">
    <img src="@/assets/svg/icons/title-icon-mob.svg"
         class="section-title-decoration mobile sm-none" />

    <img src="@/assets/svg/icons/title-icon.svg"
         class="section-title-decoration desktop" />

    <div class="content-container" id="partners">
      <h1 class="section-title">Партнеры</h1>

      <div class="partners">
        <img :src="item.image.path" v-for="item in partners" />
      </div>
    </div>

    <div class="content-container align-items-start">
      <img src="@/assets/svg/icons/title-icon-mob.svg"
           class="section-title-decoration section-title-decoration__2 mobile sm-none" />

      <img src="@/assets/svg/icons/title-icon.svg"
           class="section-title-decoration section-title-decoration__2 desktop" />

      <h1 class="section-title">Эко</h1>

      <div class="text">
        <h3>купим вторсырье или сделаем скидку!</h3>

        <p>
          ООО «ПРОФЕССИОНАЛЬНЫЕ ПОЛИМЕРЫ» придерживается мировых тенденций и на
          своем уровне вносит вклад в сохранение окружающей среды. Для этого на
          постоянной основе наша компания осуществляет выкуп отходов поставляемой
          продукции. Для осуществления данной деятельности мы открыли собственный
          распределительный центр с оборудованием для переработки и сортировки вторсырья.
        </p>

        <h3>на постоянной основе закупаем отходы:</h3>

        <div class="d-flex align-items-center flex-wrap items">
          <p class="item">стретч-пленки</p>

          <p class="item">картон</p>

          <p class="item">пвд</p>
        </div>

        <div class="d-flex align-items-center flex-wrap items">
          <p class="item">пластик</p>

          <p class="item">втулки</p>

          <p class="item">биг беби</p>
        </div>
      </div>
    </div>

    <div class="content-container content-container-full align-items-start">
      <div class="contacts">
        <img src="@/assets/svg/map-polygon.svg"
             class="polygon" />

        <img src="@/assets/svg/contacts-bottom.svg"
             class="bottom-img" />

        <img src="@/assets/svg/contacts-top.svg"
             class="top-img" />

        <h2>наши контакты</h2>

        <div class="d-flex mb-5">
          <div class="w-25 h-100">Телефон:</div>

          <div class="ml-2 h-100"
               style="margin-top: -7px;">
            <div class="weight-600">{{ getContentByKey('phone1') }}</div>

            <div class="weight-600">{{ getContentByKey('phone2') }}</div>
          </div>
        </div>

        <div class="d-flex align-items-center mb-5">
          <div class="w-25 h-100">Сайт:</div>

          <div class="ml-2 h-100 weight-500"
               style="margin-top: -7px;">
            www.profpolim.com
          </div>
        </div>

        <div class="d-flex align-items-center mb-5">
          <div class="w-25">Офис:</div>

          <div class="ml-2 weight-500"
               style="margin-top: -7px;">
            {{ getContentByKey('office') }}
          </div>
        </div>

        <div class="d-flex align-items-center mb-5">
          <div class="w-25">Склад:</div>

          <div class="ml-2 weight-500"
               style="margin-top: -7px;">
            {{ getContentByKey('stock') }}
          </div>
        </div>
      </div>
    </div>

    <img src="@/assets/map.png"
         class="map-mob"/>

    <img class="map-img"
         src="@/assets/map-img.png" />
  </section>
</template>

<script>
import contentMixin from '@/mixins/contentMixin'
import { mapState } from 'vuex'

export default {
  name: 'PartnersEcoContacts',

  mixins: [contentMixin],

  data() {
    return {
    }
  },
  computed: {
    ...mapState(['partners']),
  }
}
</script>

<style scoped lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 85px 0px;
  padding-bottom: 0;
  position: relative;
  background-image: url('@/assets/svg/section-bg-1.svg');
  background-repeat: no-repeat;
  background-size: 32%;
  background-position: top right;

  @media(max-width: 999px){
    background-image: url('@/assets/svg/section-bg-mob.svg');
    background-size: 90%;
    background-position: top right;
  }

  @media(max-width: 576px){
    background-image: url('@/assets/svg/section-bg-mob-sm.svg');
    background-size: 60%;
    background-position: top 150px right;
    padding: 67px 0px;
    padding-bottom: 0;
  }

  @media(max-width: 375px){
    background-image: url('@/assets/svg/section-bg-mob-sm.svg');
    background-size: 90%;
    background-position: top 360px right;
    padding: 67px 0px;
    padding-bottom: 0;
  }
}

.content-container {
  flex-direction: column;
}

.text {
  margin-bottom: 200px;

  @media(max-width: 576px){
    margin-bottom: 40px;
  }

  h3 {
    color: $blue;
    font-size: 25px;
    line-height: 31px;
    font-size: $regular;
    font-weight: 600 !important;
    text-transform: uppercase;
    margin-bottom: 40px !important;

    @media(max-width: 576px){
      font-size: 17px !important;
      line-height: 100% !important;
      font-weight: 400 !important;
    }
  }

  p {
    font-size: 16px;
    color: $text-gray;
    max-width: 1018px;
    line-height: 31px;
    margin-bottom: 70px;

    @media(max-width: 500px){
      font-size: 15px;
      margin-bottom: 45px;
    }
  }
}

.section-title-decoration {
  &__2 {
    margin-right: 100px;
    flex-shrink: 0;
    margin-left: -100px;
    display: flex;
    margin-top: -20px;
  }
}

.map-img {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 50px;
  margin-bottom: 50px;
  max-width: 450px;
  z-index: 1;

  @media(max-width: 1100px){
    display: none;
  }
}

.map {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  background-color: $light-blue;
  width: 358px;
  height: 358px;
  margin-bottom: 102px;
  margin-right: 101px;
  z-index: 2;

  @media(max-width: 1100px){
    display: none;
  }
}

.partners {
  margin-top: -40px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 130px;

  @media(max-width: 768px){
    margin-bottom: 100px;
  }

  @media(max-width: 500px){
    justify-content: center;
    margin-top: 0;
    margin-bottom: 50px;
  }

  img {
    max-width: 200px;
    margin-right: 55px;
    margin-top: 10px;
    margin-bottom: 10px;

    @media(max-width: 768px){
      margin-right: 25px;
      margin-bottom: 40px;
    }

    @media(max-width: 576px){
      margin-bottom: 25px;
      margin-right: 15px;
    }

    &:first-child {
      max-width: 190px;

      @media(max-width: 768px){
        max-width: 130px;
      }

      @media(max-width: 500px){
        max-width: 110px;
      }
    }

    &:nth-child(2){
      max-width: 263px;

      @media(max-width: 768px){
        max-width: 180px;
      }

      @media(max-width: 500px){
        max-width: 140px;
      }
    }

    &:nth-child(3){
      max-width: 190px;

      @media(max-width: 768px){
        max-width: 120px;
      }
    }

    &:nth-child(4){
      max-width: 150px;

      @media(max-width: 768px){
        max-width: 90px;
      }

      @media(max-width: 500px){
        max-width: 110px;
        margin-left: 15px;
      }
    }

    &:nth-child(5){
      max-width: 230px;

      @media(max-width: 768px){
        max-width: 150px;
      }

      @media(max-width: 500px){
        max-width: 120px;
      }
    }

    &:nth-child(6){
      max-width: 263px;

      @media(max-width: 768px){
        max-width: 150px;
      }

      @media(max-width: 500px){
        max-width: 120px;
      }
    }

    &:nth-child(7){
      max-width: 263px;

      @media(max-width: 768px){
        max-width: 160px;
      }

      @media(max-width: 500px){
        max-width: 130px;
      }
    }

    &:nth-child(8){
      max-width: 200px;

      @media(max-width: 768px){
        max-width: 130px;
      }

      @media(max-width: 500px){
        max-width: 100px;
      }
    }

    &:nth-child(9){
      max-width: 280px;

      @media(max-width: 768px){
        max-width: 190px;
      }

      @media(max-width: 500px){
        max-width: 130px;
      }
    }

    &:nth-child(10){
      max-width: 280px;

      @media(max-width: 768px){
        max-width: 180px;
      }

      @media(max-width: 500px){
        max-width: 120px;
      }
    }

    &:nth-child(11){
      max-width: 270px;

      @media(max-width: 768px){
        max-width: 190px;
      }

      @media(max-width: 500px){
        max-width: 130px;
      }
    }
  }
}

.item {
  text-transform: uppercase;
  color: $blue-80 !important;
  font-size: 22px;
  font-weight: 600;
  position: relative;
  margin-bottom: 35px !important;
  width: 100%;
  max-width: 200px !important;

  @media(max-width: 576px){
    max-width: none;
    margin-left: 40px !important;
  }

  &:first-child {
    margin-left: 40px;

    @media(max-width: 768px){
      margin-left: 20px;
    }
  }

  &:before {
    content: url('@/assets/svg/text-decoration.svg');
    margin-top: 5px;
    position: absolute;
    left: 0;
    margin-left: -35px;
  }
}

.content-container-full {
  @media(max-width: 1100px){
    width: 100%;
    max-width: none;
    padding: 0 !important;
  }
}

.contacts {
  background-color: $light-blue;
  position: relative;
  background-image: url('@/assets/svg/contacts-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top -20px right -40px;
  padding: 50px;
  padding-bottom: 150px;
  color: $white;
  font-size: 20px;

  @media(max-width: 1100px){
    width: 100%;
  }

  @media(max-width: 576px){
    padding: 60px 20px;
    font-size: 18px;
    background-size: 170%;
    background-position: top 80px right -90px;
  }

  .ml-2 {
    @media(max-width: 1100px){
      font-size: 30px;
    }

    @media(max-width: 576px){
      font-size: 18px;
    }
  }

  .w-25 {
    @media(max-width: 1100px){
      width: 14% !important;
    }

    @media(max-width: 576px){
      width: 32% !important;
      flex-shrink: 0 !important;
    }
  }


  .top-img {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -95px;
    margin-top: -7px;

    @media(max-width: 1100px){
      margin-left: -80px;
    }

    @media(max-width: 576px){
      display: none;
    }
  }

  .bottom-img {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -190px;
    margin-bottom: -10px;

    @media(max-width: 576px){
      display: none;
    }
  }

  .polygon {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -190px;
    margin-top: 60px;

    @media(max-width: 576px){
      display: none;
    }
  }

  h2 {
    color: $white;
    font-size: 50px;
    line-height: 62.5px;
    font-weight: 600;
    font-family: $regular;
    margin-bottom: 70px;
    text-transform: uppercase;

    @media(max-width: 576px){
      font-size: 20px;
    }
  }
}

.map-mob {
  position: relative;
  width: 100%;
  display: none;

  @media(max-width: 1100px){
    display: inherit;
  }

  img {
    width: 100%;
  }
}

.items {
  @media(max-width: 576px){
    flex-direction: column;
    align-items: flex-start !important;
  }
}
</style>
