<template>
  <section class="advantages-wrapper">
    <div class="content-container">
      <div class="adv">
        <img src="@/assets/svg/icons/adv-1.svg" />

        <p>Собственное <br> производство</p>
      </div>

      <div class="adv">
        <img src="@/assets/svg/icons/adv-2.svg" />

        <p>хранение <br> продукции 30 дней</p>
      </div>

      <div class="adv align-items-center">
        <img src="@/assets/svg/icons/adv-3.svg" />

        <p>сертификаты <br> качества</p>
      </div>

      <div class="adv">
        <img src="@/assets/svg/icons/adv-4.svg" />

        <p>на рынке <br> с 2012 года</p>
      </div>

      <div class="adv">
        <img src="@/assets/svg/icons/adv-5.svg" />

        <p>Собственный <br> автопарк</p>
      </div>

      <div class="adv">
        <img src="@/assets/svg/icons/adv-6.svg" />

        <p>предоставление <br> образцов</p>
      </div>

      <div class="adv">
        <img src="@/assets/svg/icons/adv-7.svg" />

        <p>выезд <br> специалистов</p>
      </div>

      <div class="adv">
        <img src="@/assets/svg/icons/adv-8.svg" />

        <p>персональный <br> менеджер</p>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Advantages',

  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
.advantages-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 117px 0px;
  position: relative;
  background-image: url('@/assets/svg/advs-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $light-blue;
  background-position: top left;

  @media(max-width: 1200px){
    padding: 117px 0px;
  }

  @media(max-width: 999px){
    background-image: url('@/assets/svg/advs-bg-mob.svg');
    background-position: top left;
    background-size: 110%;
  }

  @media(max-width: 576px){
    background-image: url('@/assets/svg/advs-bg-mob-sm.svg');
    background-size: cover;
    background-position: top left;
    padding: 100px 0px;
    padding-top: 60px;
  }

  @media(max-width: 320px){
    background-size: contain;
    background-position: top 50px left;
  }
}

.content-container {
  flex-wrap: wrap;
  justify-content: space-between;
}

.adv {
  display: flex;
  align-items: flex-end;
  width: 300px;
  margin-bottom: 83px;

  @media(max-width: 1200px){
    width: 50%;
    margin-bottom: 93px;

    &:nth-child(2n){
      width: 40%;
    }
  }

  @media(max-width: 730px){
    width: 100%;

    &:nth-child(2n){
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &:nth-child(4n){
    width: 180px;

    @media(max-width: 1200px){
      width: 40%;
    }

    @media(max-width: 730px){
      width: 100%;
    }
  }

  &:nth-child(n+5){
    margin-bottom: 0;

    @media(max-width: 1200px){
      margin-bottom: 93px;
    }
  }

  p {
    margin-bottom: 0;
    color: $white;
    text-transform: uppercase;
    font-family: $regular;
    font-weight: 500;
    line-height: 17px;
    font-size: 15px;
    margin-left: 20px;
  }
}
</style>
