<template>
  <footer class="d-flex justify-content-center">
    <div class="content-container">
      <div class="menu-wrapper w-100">
        <div class="mr-130">
          <p class="logo">OOO “Профессиональные полимеры”</p>

          <div class="md-show"
               style="margin-top: 60px;">
            <p>
              {{ getContentByKey('inn') }} <br> {{ getContentByKey('ogrn') }}
            </p>

            <p>Почта: {{ getContentByKey('email-footer') }}</p>

            <p>
              Офис:  {{ getContentByKey('office') }} <br>
              Склад: {{ getContentByKey('stock') }}
            </p>
          </div>
        </div>

        <div class="menu">
          <router-link :to="{ name: 'home', hash: '#main' }"
          @click.native="navigateTo('main')"
                       class="footer-link">Главная</router-link>

          <router-link :to="{ name: 'home', hash: '#about' }"
          @click.native="navigateTo('about')"
                       class="footer-link">О компании</router-link>

          <router-link :to="{ name: 'home', hash: '#certificates' }"
          @click.native="navigateTo('certificates')"
                       class="footer-link">Сертификаты</router-link>

          <router-link :to="{ name: 'home', hash: '#products-map' }"
          @click.native="navigateTo('products-map')"
                       class="footer-link">карта ассортимента</router-link>

          <router-link :to="{ name: 'home', hash: '#products' }"
          @click.native="navigateTo('products')"
                       class="footer-link">мы поставляем</router-link>

          <router-link :to="{ name: 'home', hash: '#partners' }"
          @click.native="navigateTo('partners')"
                       class="footer-link">партнеры</router-link>
        </div>
      </div>

      <div class="wrapper w-100"
           style="margin-top: 50px;">
        <div class="md-hide mb">
          <p>
            {{ getContentByKey('inn') }} <br> {{ getContentByKey('ogrn') }}
          </p>

          <p>Почта: {{ getContentByKey('email-footer') }}</p>

          <p>
            Офис:  {{ getContentByKey('office') }} <br>
            Склад: {{ getContentByKey('stock') }}
          </p>
        </div>

        <div class="mb">
          <p class="font-18">
            Свяжитесь с нами:
          </p>

          <div class="">
            <div class="d-flex align-items-center">
              <span>
                <img src="@/assets/svg/icons/phone-white.svg"
                     class="mr-2" />
              </span>

              <a :href="`tel:${getContentByKey('phone1')}`"
                 class="contact">{{ getContentByKey('phone1') }}</a>
            </div>

            <div class="mt-2 d-flex align-items-center">
              <span>
                <img src="@/assets/svg/icons/phone-white.svg"
                     class="mr-2" />
              </span>

              <a :href="`tel:${getContentByKey('phone2')}`"
                 class="contact">{{ getContentByKey('phone2') }}</a>
            </div>

            <div class="font-22 mt-4 d-flex align-items-center">
              <span>
                <img src="@/assets/svg/icons/mail-white.svg"
                     class="mr-2" />
              </span>

              <a :href="`mailto:${getContentByKey('email-service')}`"
                 class="contact">{{ getContentByKey('email-service') }}</a>
            </div>
          </div>
        </div>

        <div class="">
          <p class="font-18">
            Подпишитесь на наши новости:
          </p>

          <b-form-group
            label=""
            label-for="email-input"
          >
            <b-form-input
              id="email-input"
              v-model="email"
              type="text"
              placeholder="Email"
              :state="getValidated('email') && getEmailValidated()"
              aria-describedby="input-email-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-email-feedback">
              {{ !getEmailValidated() ? 'Некорректный email' : 'Поле обязательно для заполенения' }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-button class="form-button"
                    type="submit"
                    @click="submit">
            Подписаться
          </b-button>
        </div>
      </div>
    </div>

    <Modal v-model="isShow"
           :close="closeModal">
      <div class="modal">
        <img src="@/assets/svg/icons/modal-decoration-top.svg"
             class="top-img" />

        <p>Вы успешно подписались!</p>

        <img src="@/assets/svg/icons/modal-decoration-bot.svg"
             class="bot-img" />

        <button @click="closeModal">ок</button>
      </div>
    </Modal>
  </footer>
</template>

<script>
import apiService from '@/services/api'
import { defineComponent, ref } from 'vue'
import contentMixin from '@/mixins/contentMixin'

export default {
  name: 'FooterRegion',

  mixins: [contentMixin],

  setup() {
    const isShow = ref(false);

    function showModal() {
      isShow.value = true;
    }

    function closeModal() {
      isShow.value = false;
    }

    return {
      isShow,
      showModal,
      closeModal,
    };
  },

  data(){
    return {
      email: '',
      validated: false,
    }
  },

  methods: {
    navigateTo(el, mobile = false) {
      let ele = document.getElementById(el)

      ele.scrollIntoView({behavior: "smooth", block: 'center'});

      if (mobile) {
        this.mobileMenu = false
      }
    },
    getEmailValidated() {
      if (!this.validated) {
        return null
      }

      const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/

      return emailRegexp.test(this.email)
    },
    getValidated(prop) {
      if (!this.validated) {
        return null
      }

      return !!this[prop] || !!this[prop].trim()
    },
    async submit() {
      this.validated = true

      if (!this.getValidated('email') || !this.getEmailValidated()) {
        return
      }
      const { email } = this
      const { data } = apiService.sendFeedback({ email, type: 'subscribe' })

      this.email = ''
      this.validated = false

      this.showModal()
    },
  }
}
</script>

<style scoped lang="scss">
footer {
  font-family: $regular;
  color: $white;
  position: relative;
  background-color: #243C60;
  width: 100%;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: cover;
  background-image: url('@/assets/svg/footer-bg.svg');
  padding-top: 30px;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 80px;

  @media(max-width: 768px){
    background-image: url('@/assets/svg/footer-bg-mob.svg');
    background-size: 70%;
    background-position: top left;
  }

  @media(max-width: 576px){
    background-image: url('@/assets/svg/footer-bg-mob-sm.svg');
    background-size: 100%;
    background-position: top right;
  }
}

.content-container {
  display: flex;
  flex-direction: column;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 576px){
    flex-direction: column;
    align-items: flex-start;

    .mb {
      margin-bottom: 40px;
    }
  }
}

.menu-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 999px){
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media(max-width: 576px){
    flex-direction: column;

    p {
      margin-bottom: 30px;
    }
  }
}

.logo {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
}

.footer-link {
  color: $white !important;
  text-transform: uppercase;
  margin-right: 60px;
  font-weight: 400;
  font-size: 12px;

  @media(max-width: 1300px){
    margin-right: 25px;
  }

  @media(max-width: 999px){
    margin-right: 0;
    margin-bottom: 30px;
  }

  &:hover {
    color: $blue !important;
  }

  &:last-child {
    margin-right: 0;
  }
}

.form-button {
  width: 100%;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media(max-width: 999px){
    flex-direction: column;
    align-items: flex-start;
  }
}

.contact {
  color: $white !important;

  &:hover {
    text-decoration: underline !important;
  }
}

.md-hide {
  @media(max-width: 999px){
    display: none;
  }

  @media(max-width: 576px){
    display: block;
  }
}

.md-show {
  display: none;

  @media(max-width: 999px){
    display: inherit;
  }

  @media(max-width: 576px){
    display: none;
  }
}

.mr-130 {
  margin-right: 130px;

  @media(max-width: 720px){
    margin-right: 15%;
  }
}

.modal {
  max-width: 350px;
  padding: 30px;
  max-height: 80%;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 20px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;

  p {
    color: $dark-blue;
    font-weight: 600;
    font-size: 22px;
  }

  button {
    background-color: $blue-20;
    font-size: 14px;
    border: none;
    padding: 15px 50px !important;
    text-transform: uppercase;
    color: $white;
    transition: .6s all;

    &:hover {
      opacity: 0.7;
    }
  }

  .top-img {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -70px;
    margin-right: -94px;

    @media(max-width: 768px){
      max-width: 160px;
      margin-top: -60px;
      margin-right: 0;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
    }
  }

  .bot-img {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -58px;
    margin-bottom: -20px;

    @media(max-width: 768px){
      display: none;
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 20px;
    margin-right: 20px;
    max-width: 32px;
    max-height: 32px;
    cursor: pointer;
    transition: .6s all;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
