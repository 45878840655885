<template>
  <HeaderRegion />

  <router-view />

  <FooterRegion />
</template>

<script>
import HeaderRegion from '@/components/HeaderRegion.vue'
import FooterRegion from '@/components/FooterRegion.vue'

export default {
  components: {
    HeaderRegion,
    FooterRegion,
  }
}
</script>

<style lang="scss">

</style>
