import { createStore } from 'vuex'
import apiService from '@/services/api'

export default createStore({
  state: {
    content: [],
    partners: [],
    products: [],
    certificates: [],
  },
  getters: {
    getContentByKey: (state) => (key) => {
      const data = state.content.find(item => item.key === key) || { content: '' }

      return data.content
    }
  },
  mutations: {
    setData(state, payload) {
      state[payload.prop] = payload.data
    }
  },
  actions: {
    async getPartners({ commit }) {
      const { data } = await apiService.getPartners()

      commit('setData', {
        prop: 'partners',
        data,
      })
    },
    async getProducts({ commit }) {
      const { data } = await apiService.getProducts()

      commit('setData', {
        prop: 'products',
        data,
      })
    },
    async getContent({ commit }) {
      const { data } = await apiService.getContent()

      commit('setData', {
        prop: 'content',
        data,
      })
    },
    async getCertificates({ commit }) {
      const { data } = await apiService.getCertificates()

      commit('setData', {
        prop: 'certificates',
        data,
      })
    },
  },
  modules: {
  }
})
