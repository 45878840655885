<template>
  <section class="certification-wrapper">
    <img src="@/assets/svg/certification-polygon.svg"
         class="polygon polygon__top sm-none lg-none" />

    <img src="@/assets/svg/certification-polygon.svg"
         class="polygon polygon__bottom" />

    <img src="@/assets/svg/icons/title-icon-mob.svg"
         class="section-title-decoration mobile sm-none lg-none" />

    <img src="@/assets/svg/icons/title-icon.svg"
         class="section-title-decoration desktop" />

    <div class="content-container">
      <h1 class="section-title">сертификаты</h1>

      <div class="d-flex w-100 justify-content-between docs">
        <div class="doc"
             @click="showModal(item)"
             v-for="(item, index) in certificates" :key="item.id" v-show="index >= showedIndex && index < showedIndex + 4">
          <img :src="item.file.path" />
        </div>
      </div>
    </div>

    <div class="d-flex mt-80">
      <b-button class="prev-btn" @click="prev" :class="{'disabled-btn': showedIndex === 0}">
        <img src="@/assets/svg/icons/arrow-gray.svg" v-if="showedIndex === 0" />

        <img src="@/assets/svg/icons/arrow-white.svg"
             class="left" v-if="showedIndex !== 0" />
      </b-button>

      <b-button class="next-btn" @click="next" :class="{'disabled-btn': showedIndex === this.certificates.length - 3}">
        <img src="@/assets/svg/icons/arrow-white.svg" v-if="showedIndex < this.certificates.length - 3" />

        <img src="@/assets/svg/icons/arrow-gray.svg"
             class="right" v-if="showedIndex >= this.certificates.length - 3"/>
      </b-button>
    </div>

    <Modal v-model="isShow"
           :close="closeModal">
      <div class="modal">
        <img src="@/assets/svg/modal-decoration.svg"
             class="section-title-decoration" />

        <div class="">
          <h1 class="section-title">{{ selectedCertificate?.title || ''}}</h1>

          <img :src="selectedCertificate?.file?.path || ''" />
        </div>

        <img src="@/assets/svg/icons/close.svg"
             @click="closeModal"
             class="close" />
      </div>
    </Modal>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { defineComponent, ref } from 'vue'

export default {
  name: 'Certification',

  setup() {
    const isShow = ref(false);
    const selectedCertificate = ref(null);

    function showModal(item) {
      selectedCertificate.value = item;
      isShow.value = true;
    }

    function closeModal() {
      isShow.value = false;
    }

    return {
      isShow,
      showModal,
      closeModal,
      selectedCertificate,
    };
  },

  data() {
    return {
      showedIndex: 0,
    }
  },

  computed: {
    ...mapState(['certificates']),
  },

  methods: {
    next() {
      if (this.showedIndex < this.Certificates.length - 3) {
        this.showedIndex++
      }
    },
    prev() {
      if (this.showedIndex > 0) {
        this.showedIndex--
      }
    },
  }
}
</script>

<style scoped lang="scss">
.certification-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 85px;
  padding-bottom: 85px;
  position: relative;
  background-image: url('@/assets/svg/certification-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top -160px left;

  @media(max-width: 999px){
    background-image: url('@/assets/svg/certification-bg-mob.svg');
    background-position: top -150px right;
    background-size: 65%;
    padding-top: 149px;
  }

  @media(max-width: 768px){
    background-position: top -130px right;
    padding-bottom: 160px;
    background-size: 67%;
  }

  @media(max-width: 576px){
    background-image: url('@/assets/svg/about-us-bg-mob-sm.svg');
    background-size: 90%;
    background-position: top -75px right;
    padding: 67px 0px;
  }
}

.content-container {
  flex-direction: column;
}

.text {
  font-size: 16px;
  font-family: $regular;
  max-width: 1239px;
  padding-bottom: 30px;
  line-height: 31px;
  margin-bottom: 0;
  color: $text-gray;

  @media(max-width: 576px){
    font-size: 15px;
    padding-bottom: 10px;
  }
}

.section-title-decoration {
  margin-top: 163px;

  @media(max-width: 999px){
    margin-top: 133px;
  }
}

.docs {
  margin-left: -35px;
  margin-top: -25px;

  @media(max-width: 999px){
    margin-left: -15px;
  }

  @media(max-width: 576px){
    margin-top: 0;
    margin-left: 0;
    flex-direction: column;
    align-items: center;
  }

  .doc {
    cursor: pointer;
    transition: .6s all;
    box-shadow: 0px 7px 20px 2px rgba(103, 103, 103, 0.1);

    &:hover {
      opacity: .55;
    }

    img {
      width: 100%;
      max-width: 380px;
    }
  }
}

.polygon {
  position: absolute;
  left: 0;

  &__top {
    top: 0;
    margin-left: 20%;
    margin-top: -20px;
  }

  &__bottom {
    bottom: 0;
    margin-left: 10%;

    @media(max-width: 576px){
      margin-left: -70px;
    }
  }
}

.prev-btn, .next-btn {
  width: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue-20;
  border: none;
  height: 48px;
  border-radius: 0;
  transition: .5s all;

  &:hover,
  &:active,
  &:focus {
    background-color: $blue;
  }
}

.disabled-btn {
  pointer-events: none;
  background-color: $gray-20;
}

.left {
  transform: scale(-1);
}

.right {
  transform: scale(-1);
}

.mt-80 {
  margin-top: 80px;

  @media(max-width: 576px){
    margin-top: 40px;
  }
}

.section-title-decoration {
  margin-top: 70px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.modal {
  max-width: 800px;
  padding: 30px 40px;
  max-height: 80%;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 20px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url('@/assets/svg/modal-bg.svg');
  background-repeat: no-repeat;
  background-size: 300%;
  background-position: top -100px left -300px;

  @media(max-width: 576px){
    padding: 30px 20px;
  }

  div {
    width: 100%;
  }

  .section-title-decoration {
    margin-top: 30px;
    margin-left: -28px;

    @media(max-width: 576px){
      max-width: 60px;
    }
  }

  h1 {
    margin-left: 65px;
    margin-top: 20px;

    @media(max-width: 576px){
      margin-left: 0px;
      margin-top: 15px;
    }
  }

  img {
    max-width: 100%;
    max-height: 700px;
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 20px;
    margin-right: 20px;
    max-width: 32px;
    max-height: 32px;
    cursor: pointer;
    transition: .6s all;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
