<template>
  <section class="prices-wrapper payment">
    <img src="@/assets/svg/payment-decoration.svg"
         class="decoration" />

    <div class="content-container">
      <h3>
        Узнать <strong>условия рассрочки</strong> по вашему заказу:
      </h3>

      <div class="d-flex align-items-center w-100">
        <form ref="form"
              class="form d-flex aling-items-center w-100"
              @submit.stop.prevent="submit">
         <b-form-group
            label="Ваше имя"
            label-for="name-input"
            class="mr-2"
          >
            <b-form-input
              id="name-input"
              v-model="name"
              type="text"
              placeholder="Имя"
              :state="getValidated('name')"
              aria-describedby="input-name-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-name-feedback">Поле обязательно для заполенения</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Контактный телефон"
            label-for="phone-input"
            class="mr-2"
          >
            <b-form-input
              id="phone-input"
              v-model="phone"
              type="text"
              :state="getValidated('phone')"
              v-maska="'+7 (###) ###-##-##'"
              placeholder="+7 (___) ___-__-__"
              aria-describedby="input-phone-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="input-phone-feedback">Поле обязательно для заполенения</b-form-invalid-feedback>
          </b-form-group>

          <b-button class="form-button"
                    type="submit"
                    @click="submit">
            Узнать условия
          </b-button>
        </form>
      </div>
    </div>

    <Modal v-model="isShow"
           :close="closeModal">
      <div class="modal">
        <img src="@/assets/svg/icons/modal-decoration-top.svg"
             class="top-img" />

        <p>Заявка отправлена!</p>

        <img src="@/assets/svg/icons/modal-decoration-bot.svg"
             class="bot-img" />

        <button @click="closeModal">ок</button>
      </div>
    </Modal>
  </section>
</template>

<script>
import apiService from '@/services/api'
import { defineComponent, ref } from 'vue'

export default {
  name: 'PaymentPlan',

  setup() {
    const isShow = ref(false);

    function showModal() {
      isShow.value = true;
    }

    function closeModal() {
      isShow.value = false;
    }

    return {
      isShow,
      showModal,
      closeModal,
    };
  },

  data() {
    return {
      name: '',
      phone: '',
      validated: false,
    }
  },
  methods: {
    getValidated(prop) {
      if (!this.validated) {
        return null
      }

      if (prop === 'phone' && this.phone.length !== 18) {
        return false
      }

      return !!this[prop] || !!this[prop].trim()
    },
    async submit() {
      this.validated = true

      if ((!this.getValidated('name') || !this.getValidated('phone'))) {
        return
      }
      const { name, phone } = this
      const { data } = await apiService.sendFeedback({ name, phone, type: 'price' })

      this.$metrika.reachGoal('CREDIT_LINE')

      this.name = ''
      this.phone = ''
      this.validated = false
      this.showModal()
    },

  }
}
</script>

<style scoped lang="scss">
.prices-wrapper {
  position: relative;
  background-image: url('@/assets/svg/payment-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top -20px right;
  width: 100%;
  height: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $light-blue;

  @media(max-width: 1200px){
    height: auto;
    padding: 40px 0px;
  }

  @media(max-width: 768px){
    background-image: url('@/assets/svg/payment-bg-mob.svg');
    background-size: contain;
  }

  @media(max-width: 660px){
    margin-top: -0px;
    padding: 40px 0px;
  }

  @media(max-width: 576px){
    background-size: 230%;
    background-position: right top 20px;
  }
}

.content-container {
  flex-direction: column;
  align-items: flex-start;
}

.d-flex {
  @media(max-width: 1200px){
    flex-direction: column !important;
  }
}

strong {
  font-weight: 600 !important;
}

h3 {
  font-weight: 300 !important;
}

.decoration {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -80px;

  @media(max-width: 576px){
    margin-top: -60px;
    max-width: 130px;
  }

  @media(max-width: 414px){
    margin-top: -50px;
  }
}

.modal {
  max-width: 350px;
  padding: 30px;
  max-height: 80%;
  box-sizing: border-box;
  background-color: #fff;
  font-size: 20px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: visible;

  p {
    color: $dark-blue;
    font-weight: 600;
    font-size: 22px;
  }

  button {
    background-color: $blue-20;
    font-size: 14px;
    border: none;
    padding: 15px 50px !important;
    text-transform: uppercase;
    color: $white;
    transition: .6s all;

    &:hover {
      opacity: 0.7;
    }
  }

  .top-img {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -70px;
    margin-right: -94px;

    @media(max-width: 768px){
      max-width: 160px;
      margin-top: -60px;
      margin-right: 0;
      left: 50%;
      right: 50%;
      transform: translateX(-50%);
    }
  }

  .bot-img {
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -58px;
    margin-bottom: -20px;

    @media(max-width: 768px){
      display: none;
    }
  }

  .close {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: 20px;
    margin-right: 20px;
    max-width: 32px;
    max-height: 32px;
    cursor: pointer;
    transition: .6s all;

    &:hover {
      opacity: 0.7;
    }
  }
}
</style>
