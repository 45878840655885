import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Maska from 'maska'
import { BootstrapVue } from 'bootstrap-vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import contentMixin from '@/mixins/contentMixin'
import VueYandexMetrika from 'vue3-yandex-metrika'
import VueUniversalModal from 'vue-universal-modal'

import '@/styles/scss/_templates.scss'
import 'bootstrap/dist/css/bootstrap.css'
import 'vue-universal-modal/dist/index.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const app = createApp(App).use(store).use(router).use(BootstrapVue3);

app.use(Maska);

app.use(VueYandexMetrika, {
    id: 57499116,
    router: router,
    env: process.env.NODE_ENV,
})

app.mount('#app');

app.use(VueUniversalModal, {
  teleportTarget: '#app',
})
