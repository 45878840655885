<template>
  <section class="about-wrapper">
    <img src="@/assets/svg/icons/title-icon-mob.svg"
         class="section-title-decoration mobile sm-none" />

    <img src="@/assets/svg/icons/title-icon.svg"
         class="section-title-decoration desktop" />

    <div class="content-container">
      <h1 class="section-title">о компании</h1>

      <p class="text" v-html="getContentByKey('about')">
      </p>
    </div>
  </section>
</template>

<script>
import contentMixin from '@/mixins/contentMixin'

export default {
  name: 'AboutUs',

  mixins: [contentMixin],

  data() {
    return {

    }
  },
}
</script>

<style scoped lang="scss">
.about-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 85px 0px;
  position: relative;
  background-image: url('@/assets/svg/about-us-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top -30px left;

  @media(max-width: 999px){
    background-image: url('@/assets/svg/about-us-mob.svg');
    background-position: top -150px right;
    background-size: 65%;
    padding: 85px 0px;
  }

  @media(max-width: 576px){
    background-image: url('@/assets/svg/about-us-bg-mob-sm.svg');
    background-size: cover;
    background-position: top -35px right;
    padding: 67px 0px;
  }
}

.content-container {
  flex-direction: column;
}

.text {
  font-size: 16px;
  font-family: $regular;
  max-width: 1239px;
  padding-bottom: 30px;
  line-height: 31px;
  margin-bottom: 0;
  color: $text-gray;

  @media(max-width: 576px){
    font-size: 15px;
    padding-bottom: 10px;
  }
}
</style>
