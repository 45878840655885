<template>
  <section class="products-wrapper">
    <img src="@/assets/svg/icons/title-icon-mob.svg"
         class="section-title-decoration mobile sm-none" />

    <img src="@/assets/svg/icons/title-icon.svg"
         class="section-title-decoration desktop" />

    <div class="content-container">
      <h1 class="section-title">Мы поставляем</h1>

      <transition>
        <div class="content">
          <div class="product" v-for="(item, index) in products" :key="item.id" v-show="index >= showedIndex && index < showedIndex + 4">
            <img :src="item.image.path"
                 class="w-100" />
          </div>
        </div>
      </transition>


      <div class="d-flex mt-80">
        <b-button class="prev-btn" @click="prev" :class="{'disabled-btn': showedIndex === 0}">
          <img src="@/assets/svg/icons/arrow-gray.svg" v-if="showedIndex === 0" />

          <img src="@/assets/svg/icons/arrow-white.svg"
               class="left" v-if="showedIndex !== 0" />
        </b-button>

        <b-button class="next-btn" @click="next" :class="{'disabled-btn': showedIndex === this.products.length - 4}">
          <img src="@/assets/svg/icons/arrow-white.svg" v-if="showedIndex < this.products.length - 4" />

          <img src="@/assets/svg/icons/arrow-gray.svg"
               class="right" v-if="showedIndex >= this.products.length - 4"/>
        </b-button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Products',

  data() {
    return {
      showedIndex: 0,
    }
  },
  computed: {
    ...mapState(['products']),
  },
  methods: {
    next() {
      if (this.showedIndex < this.products.length - 4) {
        this.showedIndex++
      }
    },
    prev() {
      if (this.showedIndex > 0) {
        this.showedIndex--
      }
    },
  }
}
</script>

<style scoped lang="scss">
.products-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 200px 0px;
  background-image: url('@/assets/svg/products-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-position: top left;

  @media(max-width: 999px){
    padding: 85px 0px;
    padding-bottom: 130px;
    background-position: top -20px left;
    background-image: url('@/assets/svg/products-bg-mob.svg');
  }

  @media(max-width: 576px){
    background-size: 215%;
    background-position: top -30px right -30px;
    padding: 67px 0px;
    padding-bottom: 130px;
  }
}

.content-container {
  flex-direction: column;

  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .product {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 270px;
      background-color: $white;
      border: 1px solid #DED6D6;
      margin-right: 20px;
      height: 310px;

      &:last-child {
        margin-right: 0;
      }

      @media(max-width: 999px){
        width: 190px;
        height: 210px;
      }

      @media(max-width: 768px){
        width: 150px;
        height: 170px;
      }

      @media(max-width: 576px){
        width: 120px;
        height: 140px;
      }
    }
  }
}

.section-title-decoration {
  margin-top: 180px;
}

.prev-btn, .next-btn {
  width: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $blue-20;
  border: none;
  height: 48px;
  border-radius: 0;
  transition: .5s all;

  &:active,
  &:focus {
    background-color: $blue !important;
  }

  &:hover {
    background-color: $blue;
  }
}

.disabled-btn {
  pointer-events: none;
  background-color: $gray-20;
}

.left {
  transform: scale(-1);
}

.right {
  transform: scale(-1);
}

.mt-80 {
  margin-top: 80px;

  @media(max-width: 576px){
    margin-top: 40px;
  }
}

.section-title-decoration {
  margin-top: 70px;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
