<template>
  <div class="home"
       id="home">
    <PromoSection id="main" />

    <PricesBlock />

    <AboutUs id="about" />

    <Advantages />

    <Certification id="certificates" />

    <ProductsRange id="products-map" />

    <Products id="products" />

    <Video />

    <PaymentPlan />

    <PartnersEcoContacts />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import Video from '@/components/Video.vue'
import AboutUs from '@/components/AboutUs.vue'
import Products from '@/components/Products.vue'
import Advantages from '@/components/Advantages.vue'
import PaymentPlan from '@/components/PaymentPlan.vue'
import PricesBlock from '@/components/PricesBlock.vue'
import PromoSection from '@/components/PromoSection.vue'
import ProductsRange from '@/components/ProductsRange.vue'
import Certification from '@/components/Certification.vue'
import PartnersEcoContacts from '@/components/PartnersEcoContacts.vue'

export default {
  name: 'HomeView',
  components: {
    Video,
    AboutUs,
    Products,
    Advantages,
    PaymentPlan,
    PricesBlock,
    PromoSection,
    Certification,
    ProductsRange,
    PartnersEcoContacts,
  },

  setup () {
    const store = useStore()

    store.dispatch('getContent')
    store.dispatch('getProducts')
    store.dispatch('getPartners')
    store.dispatch('getCertificates')
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
