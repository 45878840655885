<template>
  <div>
    <header
      class="d-flex justify-content-between align-items-center"
      :class="{ 'bg-white': headerFixed }"
    >
      <div class="font-blue logo">
        Профессиональные <br class="desktop"> полимеры
      </div>

      <div class="desktop">
        <div class="w-100 d-flex justify-content-end pb-3 pr-95">
          <div class="font-18">
            <span>
              <img src="@/assets/svg/icons/mail.svg"
                   class="mr-2" />
            </span>

            <a :href="`mailto:${getContentByKey('email-header')}`"
               class="normal">{{ getContentByKey('email-header') }}</a>
          </div>

          <div class="ml-5 mr-5">
            <span>
              <img src="@/assets/svg/icons/phone.svg"
                   class="mr-2" />
            </span>

            <a :href="`tel:${getContentByKey('phone1')}`"
               class="normal">{{ getContentByKey('phone1') }}</a>
          </div>

          <div class="">
            <span>
              <img src="@/assets/svg/icons/phone.svg"
                   class="mr-2" />
            </span>

            <a :href="`tel:${getContentByKey('phone2')}`"
               class="normal">{{ getContentByKey('phone2') }}</a>
          </div>
        </div>

        <div class="flex border-top pt-4 pr-95">
          <router-link :to="{ name: 'home', hash: '#main' }"
          @click.native="navigateTo('main')"
                       class="header-link">Главная</router-link>

          <router-link :to="{ name: 'home', hash: '#about' }"
          @click.native="navigateTo('about')"
                       class="header-link">О компании</router-link>

          <router-link :to="{ name: 'home', hash: '#certificates' }"
          @click.native="navigateTo('certificates')"
                       class="header-link">Сертификаты</router-link>

          <router-link :to="{ name: 'home', hash: '#products-map' }"
          @click.native="navigateTo('products-map')"
                       class="header-link">карта ассортимента</router-link>

          <router-link :to="{ name: 'home', hash: '#products' }"
          @click.native="navigateTo('products')"
                       class="header-link">мы поставляем</router-link>

          <router-link :to="{ name: 'home', hash: '#partners' }"
          @click.native="navigateTo('partners')"
                       class="header-link">партнеры</router-link>
        </div>
      </div>

      <div class="mobile flex align-items-center">
        <div class="font-18 md-none">
          <span>
            <img src="@/assets/svg/icons/mail.svg"
                 class="mr-2" />
          </span>

          {{ getContentByKey('phone1') }}

          <span>
            <img src="@/assets/svg/icons/arrow-down.svg"
                 class="ml-2" />
          </span>
        </div>

        <img src="@/assets/svg/icons/menu-bar.svg"
             class="cursor-pointer basic-hover ml-4"
             v-if="!mobileMenu"
             @click="mobileMenu = !mobileMenu" />
      </div>
    </header>

    <div class="mobile-menu"
         v-if="mobileMenu">
      <img src="@/assets/svg/icons/close.svg"
           class="close"
           @click="mobileMenu = !mobileMenu" />

      <p>Меню</p>

      <router-link :to="{ name: 'home', hash: '#main' }"
      @click.native="navigateTo('main', true)"
                   class="header-link">Главная</router-link>

      <router-link :to="{ name: 'home', hash: '#about' }"
      @click.native="navigateTo('about', true)"
                   class="header-link">О компании</router-link>

      <router-link :to="{ name: 'home', hash: '#certificates' }"
      @click.native="navigateTo('certificates', true)"
                   class="header-link">Сертификаты</router-link>

      <router-link :to="{ name: 'home', hash: '#products-map' }"
      @click.native="navigateTo('products-map', true)"
                   class="header-link">карта ассортимента</router-link>

      <router-link :to="{ name: 'home', hash: '#products' }"
      @click.native="navigateTo('products', true)"
                   class="header-link">мы поставляем</router-link>

      <router-link :to="{ name: 'home', hash: '#partners' }"
      @click.native="navigateTo('partners', true)"
                   class="header-link">партнеры</router-link>

      <div class="font-18">
        <span>
          <img src="@/assets/svg/icons/mail.svg"
               class="mr-2" />
        </span>

        <a :href="`mailto:${getContentByKey('email-header')}`"
           class="normal">{{ getContentByKey('email-header') }}</a>
      </div>

      <div class="d-sm mt-5 d-flex align-items-center">
        <span>
          <img src="@/assets/svg/icons/phone.svg"
               class="mr-2" />
        </span>

        <a :href="`tel:${getContentByKey('phone1')}`"
           class="normal">{{ getContentByKey('phone1') }}</a>
      </div>

      <div class="d-sm mt-3 d-flex align-items-center">
        <span>
          <img src="@/assets/svg/icons/phone.svg"
               class="mr-2" />
        </span>

        <a :href="`tel:${getContentByKey('phone2')}`"
           class="normal">{{ getContentByKey('phone2') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import contentMixin from '@/mixins/contentMixin'

export default {
  name: 'HeaderRegion',

  mixins: [contentMixin],

  data(){
    return {
      mobileMenu: false,
      headerFixed: false,
    }
  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll, true);

    this.headerFixed = window.scrollTop > 100
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll (event) {
      this.headerFixed = event.target.scrollTop > 100
    },
    navigateTo(el, mobile = false) {
      let ele = document.getElementById(el)

      ele.scrollIntoView({behavior: "smooth", block: 'center'});

      if (mobile) {
        this.mobileMenu = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
header {
  padding-left: 95px;
  padding-top: 19px;
  padding-bottom: 30px;
  font-family: $regular;
  color: $blue;
  position: fixed;
  background-color: transparent;
  width: 100%;
  z-index: 999;

  @media(max-width: 1200px){
    padding-left: 40px;
  }

  @media(max-width: 999px){
    padding-right: 40px;
    border: 1px solid rgba(43, 170, 225, 0.25);
    padding-bottom: 19px;
  }

  @media(max-width: 576px){
    padding: 15px;
  }
}

.bg-white {
  background-color: white;
}

.logo {
  font-family: $condensed;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-transform: uppercase;
  color: $blue-20;

  @media(max-width: 999px){
    font-size: 18px;
  }

  @media(max-width: 576px){
    max-width: 180px;
  }
}

div {
  width: fit-content;
}

.header-link {
  text-decoration: none;
  margin-right: 45px;

  @media(max-width: 1200px){
    margin-right: 25px;
  }

  @media(max-width: 999px){
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.border-top {
  border-color: rgba(43, 170, 225, 0.25) !important;
}

.mobile-menu {
  position: absolute;
  width: 100vw;
  background-color: $white;
  height: 100%;
  max-height: 90vh;
  background-image: url('@/assets/svg/mobile-menu-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top -50px right -170px;
  display: flex;
  flex-direction: column;
  z-index: 99999;
  padding: 0 40px;
  padding-top: 10px;
  padding-bottom: 55px;
  align-items: flex-end;

  @media(max-width: 768px){
    background-position: top -55px right -200px;
  }

  @media(max-width: 526px){
    padding: 0 20px;
    padding-top: 10px;
    padding-bottom: 50px;
    background-size: cover;
    background-position: top -55px right -200px;
  }

  .close {
    width: fit-content;
    cursor: pointer;
    float: right;
    margin-right: 0px;
    transition: .5s all;
    margin-top: 10px;

    &:hover {
      opacity: .6;
    }
  }

  p {
    width: 100%;
    text-align: left;
    color: #2D9CDB;
    font-size: 30px;
    font-weight: 700;
    line-height: 37.5px;
    margin-bottom: 36px;
    text-transform: uppercase;
  }

  div {
    width: 100%;
    text-align: left;
  }

  .header-link {
    font-size: 22px;
    line-height: 27px;
    font-weight: 500;
    width: 100%;
    text-align: left;
    color: $dark-blue;
    text-transform: uppercase;
    margin-bottom: 40px;
  }

  .font-18 {
    width: 100%;
    text-align: left;
  }
}
</style>
