<template>
  <section class="d-flex justify-content-center">
    <b-container fluid
                 class="content-container">
      <img src="@/assets/logo.png" />

      <img src="@/assets/svg/polygon.svg"
           class="polygon" />

      <h1>
        Комплексные поставки <br class="md-none"> упаковочных материалов
      </h1>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'PromoSection',
}
</script>

<style scoped lang="scss">
section {
  background-image: url('@/assets/svg/first-screen-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  width: 100%;
  height: 100%;
  padding: 145px 0;

  @media(max-width: 999px){
    background-size: 45%;
    background-position: right top;
  }

  @media(max-width: 720px){
    padding-top: 80px;
    background-size: 30%;
  }

  @media(max-width: 660px){
    padding: 0;
    margin-top: 120px;
    background-image: none;
  }
}

img {
  max-width: 520px;
  margin-left: -45px;

  @media(max-width: 1200px){
    max-width: 430px;
  }

  @media(max-width: 999px){
    max-width: 380px;
    margin-left: -35px;
  }

  @media(max-width: 768px){
    max-width: 290px;
  }

  @media(max-width: 720px){
    margin-left: 0;
  }
}

.polygon {
  display: none;

  @media(max-width: 660px){
    display: block;
    position: absolute;
    z-index: 99;
    right: 0;
    margin-right: 40px;
    margin-top: -10px;
  }
}

h1 {
  text-transform: uppercase;
  font-family: $scada;
  font-size: 56px;
  font-weight: 700;
  line-height: 63px;
  margin-top: 40px;
  margin-bottom: 0;
  margin-left: -10px;
  color: $blue-20;

  @media(max-width: 1250px){
    font-size: 40px;
    line-height: 100%;
  }

  @media(max-width: 999px){
    font-size: 32px;
    line-height: 45px;
    margin-left: 0;
    margin-top: 0px;
  }

  @media(max-width: 720px){
    max-width: 320px;
    padding-top: 30px;
  }

  @media(max-width: 660px){
    padding-bottom: 50px;
    max-width: none;
    padding-left: 15px;
    padding-right: 15px;
    background-image: url('@/assets/svg/first-screen-bg-mob.svg');
    background-repeat: no-repeat;
    background-position: top right;
  }
}
</style>
