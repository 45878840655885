import axios from 'axios'

// axios.defaults.baseURL = 'http://localhost/'

export default {
  async getPartners() {
    return axios.get('api/partners')
  },
  async getProducts() {
    return axios.get('api/products')
  },
  async getContent() {
    return axios.get('api/content')
  },
  async getCertificates() {
    return axios.get('api/certificates')
  },
  async sendFeedback(payload) {
    return axios.post('api/feedback', payload)
  },
}
